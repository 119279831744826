import React, { useLayoutEffect, useRef } from "react";
import {
  InputSelect,
  SearchBox,
  InputDateRange,
} from "@bluesilodev/timhutcomponents";

import { getCurrentWeekDates } from "utils/utils";

const ListInputSelect = ({
  setEmployeeViewInput,
  dateValue,
  setDateValue,
  setFieldValue,
  dataEmployees,
  keywordValue,
  setKeywordValue,
}) => {
  const inputRef = useRef();

  // Auto-focus SearchBox when keyword or employees data changes
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [keywordValue, dataEmployees]);

  const handleChange = (e) => {
    setEmployeeViewInput(e.value);  // Update the selected view
  };

  const handleTodayDate = () => {
    const weekDates = getCurrentWeekDates();

    setDateValue(weekDates);
  };


  return (
    <>
      <div className="flex gap-3 justify-between">
        {/* InputSelect for Employee View */}
        <InputSelect
          value={"Employee View"}
          disabled={false}  // Disabled as per the original code
          title={"View"}
        //   classname={"w-[200px]"}
          options={[
            { label: "Employee View", value: "Employee View" },
            { label: "Role View", value: "Role View" },
          ]}
          onChange={handleChange}
        />

        {/* Date Range Picker */}
        <InputDateRange
          name={"date"}
          value={dateValue}
          setFieldValue={setFieldValue}  // Updates the date range value
        />

        {/* Today Button */}
        <button onClick={handleTodayDate}>
          <span className="w-[80px] h-[60px] bg-white flex justify-center items-center border-[1px] border-black rounded-md hover:bg-gray-400">
            <h1 className="text-sm">Today</h1>
          </span>
        </button>

        {/* Search Box for Keyword Filtering */}
        <SearchBox
          placeholder="Search"
          value={keywordValue}  // Binds the search keyword state
          ref={inputRef}  // Reference for auto-focusing
          onChange={(e) => setKeywordValue(e.target.value)}  // Updates keyword on typing
        />
      </div>
    </>
  );
};


export default ListInputSelect;