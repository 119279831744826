import { useQuery, useQueryClient } from 'react-query';

import { customAxios } from "utils/axios";
import { alertError, alertSuccess } from 'utils/alert';

const handlePostRegisterEmployee = async (val) => {
    try {
        const res = await customAxios.post(`/api/employee/registerEmployee`, val);
        return res.data;
    } catch (error) {
        console.error("Register User Error:", error);
        alertError(error.response?.data?.message ?? "Failed to Register");
        throw error;
    }
};


const handlePostRegisterCompany = async (val) => {
    try {
        const res = await customAxios.post(`/api/employee/registerCompany`, val);
        return res.data;
    } catch (error) {
        console.error("Register Company Error:", error);
        alertError(error.response?.data?.message ?? "Failed to Register");
        throw error;
    }
};


const handlePostLogin = async (val) => {
    try {
        const res = await customAxios.post(`/api/auth/login`, val);
        return res.data;
    } catch (error) {
        console.error("Login error:", error);
        alertError(error.response?.data?.message ?? "Failed to login");
        throw error;
    }
};

const handlePostForgotPassword = async (val) => {
    try {
        const res = await customAxios.post(`/api/auth/forgotPassword`, val);
        return res.data;
    } catch (error) {
        console.error("Forgot Password error:", error);
        alertError(error.response?.data?.message ?? "Failed to Send Forgot Password Data");
        throw error;
    }
};

const handlePostResetPassword = async (val) => {
    try {
        const res = await customAxios.post(`/api/auth/resetPassword`, val);
        return res.data;
    } catch (error) {
        console.error("Reset Password error:", error);
        alertError(error.response?.data?.message ?? "Failed to Send Reset Password Data");
        throw error;
    }
};


const useGetEventQuery = () => {
    return useQuery(['getEvent'], async () => {
        const { data } = await customAxios.get(`/api/event/thisMonth`);
        return data;
    });
};



const useGetBirthdayQuery = () => {
    return useQuery(['getBirthday'], async () => {
        const { data } = await customAxios.get(`/api/employeeuser/hbdEmployee`);
        return data;
    });
};

const useGetLocationQuery = () => {
    return useQuery(['getLocation'], async () => {
        const { data } = await customAxios.get(`/api/employee/getLocation`);
        return data;
    });
};




export {
    useGetEventQuery,
    useGetBirthdayQuery,
    handlePostLogin,
    handlePostRegisterCompany,
    handlePostRegisterEmployee,
    handlePostForgotPassword,
    handlePostResetPassword,
    useGetLocationQuery,
};