import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// const Tabs = ({
//     options,
//     value,
//     width,
//     onClick,
//     day,
//     ...props
// }) => {
//     const [currentTab, setCurrentTab] = useState(value || options[0]?.id);

//     useEffect(() => {
//         setCurrentTab(value || options[0]?.id);
//     }, [value, options]);

//     const handleClick = (e) => {
//         setCurrentTab(e.target.id);
//         if (onClick) onClick(e); // Call the onClick prop if it is provided
//     };

//     return (
//         <div className="flex flex-col w-full gap-1" {...props}>
//             <ul className="flex w-full border-2 border-gray-100 rounded-md p-1 justify-around text-xs font-medium text-center text-gray-500 dark:text-gray-400 space-x-1">
//                 {options.map((option, i) => (
//                     <button
//                         key={i}
//                         id={option.id}
//                         onClick={handleClick}
//                         className={`inline-block px-3 py-2 rounded-lg ${currentTab === option.id
//                             ? "bg-orange-500 text-white"
//                             : "bg-gray-300 text-gray-500"
//                             } hover:text-white hover:bg-orange-500`}
//                         style={{ minWidth: width ? width : 'auto' }}
//                     >
//                         {option.value}
//                     </button>
//                 ))}
//             </ul>
//             <div className="w-full rounded-lg p-1 border-2 border-gray-100 overflow-y-auto">
//                 {options.map(option => (
//                     currentTab === option.id && (
//                         <div key={option.id}>
//                             {option.dataList && option.dataList.map((dataItem) => {
//                                 const itemDay = dataItem.date.split('-')[2]; // Get the day part of the date
//                                 const isHighlighted = itemDay === day; // Check if the current day matches the passed day prop
//                                 return (
//                                     <div key={itemDay} className={`w-full flex mb-2 ${isHighlighted ? 'bg-orange-200' : ''}`}>
//                                         <div className="w-1/4 flex justify-center">
//                                             <img src={dataItem.imgSrc} alt="Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
//                                         </div>
//                                         <div className="w-3/4 flex flex-col justify-between pl-2">
//                                             <p className="text-sm font-semibold">{dataItem.name}</p>
//                                             <p className="text-xs text-gray-400">{dataItem.date}</p>
//                                         </div>
//                                     </div>
//                                 );
//                             })}
//                         </div>
//                     )
//                 ))}
//             </div>
//         </div>
//     );
// };


const Tabs = ({
    options,
    value,
    width,
    onClick,
    day,
    ...props
}) => {
    const [currentTab, setCurrentTab] = useState(value || options[0]?.id);

    useEffect(() => {
        setCurrentTab(value || options[0]?.id);
    }, [value, options]);

    const handleClick = (e) => {
        setCurrentTab(e.target.id);
        if (onClick) onClick(e); // Call the onClick prop if it is provided
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Get only the date part (YYYY-MM-DD)
    };

    return (
        // <div className="flex flex-col w-full gap-1" {...props}>
        //     <ul className="flex w-full border-2 border-gray-100 rounded-md p-1 justify-around text-xs font-medium text-center text-gray-500 dark:text-gray-400 space-x-1">
        //         {options.map((option, i) => (
        //             <button
        //                 key={i}
        //                 id={option.id}
        //                 onClick={handleClick}
        //                 className={`inline-block px-3 py-2 rounded-lg ${currentTab === option.id
        //                     ? "bg-orange-500 text-white"
        //                     : "bg-gray-300 text-gray-500"
        //                     } hover:text-white hover:bg-orange-500`}
        //                 style={{ minWidth: width ? width : 'auto' }}
        //             >
        //                 {option.value}
        //             </button>
        //         ))}
        //     </ul>
        //     <div className="w-full rounded-lg p-1 border-2 border-gray-100 overflow-y-auto">
        //         {options.map(option => (
        //             currentTab === option.id && option.dataList && option.dataList.length > 0 && (
        //                 <div key={option.id}>
        //                     {option.dataList.map((dataItem, index) => {
        //                         const itemDate = formatDate(dataItem.date);
        //                         const itemDay = parseInt(itemDate.split('-')[2], 10);// Get the day part of the date
        //                         const isHighlighted = itemDay === parseInt(day, 10); // Check if the current day matches the passed day prop
        //                         return (
        //                             <div key={index} className={`w-full flex mb-1 ${isHighlighted ? 'bg-orange-200' : ''}`}>
        //                                 <div className="w-1/4 flex justify-center">
        //                                     <img src={dataItem.imgSrc} alt="Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
        //                                 </div>
        //                                 <div className="w-3/4 flex flex-col justify-between pl-2 pt-1 pb-1">
        //                                     <p className="text-sm font-semibold mb-1">
        //                                         {option.id === '2' ? `${dataItem.name} - ${dataItem.employeeName}` : dataItem.name}
        //                                     </p>
        //                                     <p className="text-xs text-gray-400">{itemDate}</p>
        //                                 </div>
        //                             </div>
        //                         );
        //                     })}
        //                 </div>
        //             )
        //         ))}
        //     </div>
        // </div>
         <div className="flex flex-col w-full gap-1" {...props}>
         <ul className="flex w-full border-2 border-gray-100 rounded-md p-1 justify-around text-xs font-medium text-center text-gray-500 dark:text-gray-400 space-x-1">
             {options.map((option, i) => (
                 <button
                     key={i}
                     id={option.id}
                     onClick={handleClick}
                     className={`inline-block px-3 py-2 rounded-lg ${currentTab === option.id
                         ? "bg-orange-500 text-white"
                         : "bg-gray-300 text-gray-500"
                         } hover:text-white hover:bg-orange-500`}
                     style={{ minWidth: width ? width : 'auto' }}
                 >
                     {option.value}
                 </button>
             ))}
         </ul>
         <div className="w-full rounded-lg p-1 border-2 border-gray-100 overflow-y-auto">
             {options.map(option => (
                 currentTab === option.id && (
                     <div key={option.id}>
                         {option.dataList && option.dataList.length > 0 ? (
                             option.dataList.map((dataItem, index) => {
                                 const itemDate = formatDate(dataItem.date);
                                 const itemDay = parseInt(itemDate.split('-')[2], 10); // Get the day part of the date
                                 const isHighlighted = itemDay === parseInt(day, 10); // Check if the current day matches the passed day prop
                                 return (
                                     <div key={index} className={`w-full flex mb-1 ${isHighlighted ? 'bg-orange-200' : ''}`}>
                                         <div className="w-1/4 flex justify-center">
                                             <img src={dataItem.imgSrc} alt="Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                                         </div>
                                         <div className="w-3/4 flex flex-col justify-between pl-2 pt-1 pb-1">
                                             <p className="text-sm font-semibold mb-1">
                                                 {option.id === '2' ? `${dataItem.name} - ${dataItem.employeeName}` : dataItem.name}
                                             </p>
                                             <p className="text-xs text-gray-400">{itemDate}</p>
                                         </div>
                                     </div>
                                 );
                             })
                         ) : (
                             // Render 'No event' when no dataItems are available
                             <div className="w-full flex justify-center items-center p-2">
                                 <p className="text-sm font-semibold mb-1">No Event</p>
                                 <p className="text-xs text-gray-400"></p> {/* Empty text */}
                             </div>
                         )}
                     </div>
                 )
             ))}
         </div>
     </div>
    );
};




Tabs.propTypes = {
    options: PropTypes.array,
    value: PropTypes.string,
    onClick: PropTypes.func,
    day: PropTypes.string,
};

Tabs.defaultProps = {
    options: [],
    value: "",
    onClick: () => { },
    day: '',
};

export default Tabs;
