import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useGeolocated } from 'react-geolocated';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button,
    TextArea,
    InputSelect
} from '@bluesilodev/timhutcomponents';

import CameraComponent from 'components/camera';
import LocationComponent from 'components/locationTracking';
import { handlePostPunchIn } from 'services/attendanceAPI';
import { useGetLocationQuery } from "services/employeeAPI";
import { useGetSchedulingShiftByUserID } from 'services/schedulingAPI';
import { setPunchInTime } from 'store/reducer/time';
import { processTimeData } from 'utils/utils';
import { extractPostalCode } from 'utils/utils';

import CloseIcon from 'assets/close.svg';
import TimeIcon from 'assets/time.svg';

import { validationSchema } from './schema';
import { alertSuccess, alertError } from 'utils/alert';

const PunchInForm = ({ onClose, handleFormSubmit }) => {
    const { currentUser } = useSelector((state) => state.userData);
    const [locationDataApi, setLocationDataApi] = useState([]);
    const [currentValueLocation, setCurrentValueLocation] = useState('');
    const [shiftOption, setShiftOption] = useState([]);
    const [locationOption, setLocationOption] = useState([]);
    const [jobPositionOption, setJobPositionOption] = useState([]);
    const [locationData, setLocationData] = useState({});
    const dispatch = useDispatch();
    const { coords } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: 5000,
    });
    const { data: resLocation, isSuccess: isSuccessLocation } = useGetLocationQuery();
    const { data: resScheduling, isSuccess: isSuccessScheduling } = useGetSchedulingShiftByUserID(currentUser.uId);

    useEffect(() => {
        if (isSuccessLocation && resLocation.data) {
            setLocationDataApi(resLocation.data.data || [])
            setLocationOption(resLocation.data.data.map((value) => ({ label: value.locationName, value: value.locationName })) || []);
            setJobPositionOption([]);
        }
    }, [isSuccessLocation, resLocation]);

    useEffect(() => {
        if (isSuccessScheduling && resScheduling?.assignShiftsByDate) {
            setShiftOption(resScheduling?.assignShiftsByDate?.[0]?.shifts.map((val) => ({ label: val.shift.shiftName, value: val.shift.uId })) || [])
        }
    }, [isSuccessScheduling, resScheduling]);

    useEffect(() => {
        if (currentValueLocation) {
            const combinedData = locationDataApi.filter((val) => val.locationName === currentValueLocation).flatMap((location) => {
                return location.departments.flatMap((department) => department.jobPosition);
            });
            setJobPositionOption(combinedData.map((val) => ({ label: val, value: val })));
            return;
        }
        setJobPositionOption([]);
    }, [currentValueLocation, locationDataApi]);

    useEffect(() => {
        if (coords) {
            const fetchAddress = async () => {
                const { latitude, longitude } = coords;
                const apiKey = process.env.REACT_APP_TOM_TOM_API_KEY;
                const url = `https://api.tomtom.com/search/2/reverseGeocode/${latitude},${longitude}.json?key=${apiKey}`;

                try {
                    const response = await axios.get(url);
                    const addressData = response.data.addresses[0].address;
                    const address = addressData.freeformAddress;
                    const postalCode = addressData.postalCode || extractPostalCode(address);
                    setLocationData({
                        address: address,
                        lat: latitude,
                        long: longitude,
                        postalCode: postalCode,
                    });
                } catch (error) {
                    console.error('Error fetching address:', error);
                }
            };

            fetchAddress();
        }
    }, [coords]);

    // const { mutate, isLoading } = usePostPunchInDataMutation(handleFormSubmit, onClose, dispatch);

    const buttonLabel = (
        <div className="flex w-full justify-center gap-2">
            <img src={TimeIcon} alt="Time Icon" />
            <p className="text-sm">Punch In</p>
        </div>
    );
    const cancellabel = (
        <div className="flex w-full justify-center gap-2">
            <img src={CloseIcon} alt="Close Icon" />
            <p className="text-sm">Cancel</p>
        </div>
    )

    return (
        <Formik
            initialValues={{ shift: 'Morning Shift', locations: 'Cafe Halim', jobPositions: "Cafe Halim", punchInDesc: '' }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                try {
                    const formData = new FormData();
                    for (const key in values) {
                        formData.append(key, values[key]);
                    }

                    formData.append('punchInGps', JSON.stringify(locationData));

                    // Log form data contents
                    for (const pair of formData.entries()) {
                        console.log(`${pair[0]}: ${pair[1]}`);
                    }

                    const resp = await handlePostPunchIn(formData);
                    if (resp.data && resp.data.data.punchIn) {
                        const formattedPunchInTime = processTimeData(resp.data.data.punchIn);
                        dispatch(setPunchInTime(formattedPunchInTime));
                        console.log(`Formatted Punch In Time: ${formattedPunchInTime}`);
                    }
                    alertSuccess('Success Sending Employee Punch In Data');
                    handleFormSubmit();
                    onClose();
                } catch (error) {
                    console.error('Error during punch in submission:', error);
                    alertError(`Failed to send Employee Punch In Data: ${error.response.data.message || ""}`);
                }
            }}
        >
            {({ handleChange, values, errors, touched, setFieldValue }) => (
                <Form>
                    <div className="form-group grid grid-cols-2 gap-6 p-3">
                        <div className="col-span-1">
                            <InputSelect
                                name="shift"
                                title="Shift"
                                options={shiftOption}
                                className="h-[20px]"
                                required
                                value={values.shift}
                                onChange={handleChange}
                                error={touched.shift && errors.shift ? errors.shift : ''}
                            />
                        </div>
                        <div className="col-span-1"></div>
                        <div className="col-span-1">
                            <InputSelect
                                name="locations"
                                title="Locations"
                                options={locationOption}
                                className="h-[20px]"
                                required
                                value={values.locations}
                                onChange={(e) => {
                                    setCurrentValueLocation(e.target.value);
                                    handleChange(e);
                                }}
                                error={touched.locations && errors.locations ? errors.locations : ''}
                            />
                        </div>
                        <div className="col-span-1">
                            <InputSelect
                                name="jobPositions"
                                title="Job Positions"
                                options={jobPositionOption}
                                className="h-[20px]"
                                required
                                value={values.jobPositions}
                                onChange={handleChange}
                                error={touched.jobPositions && errors.jobPositions ? errors.jobPositions : ''}
                            />
                        </div>
                        <div className="col-span-2">
                            <LocationComponent address={locationData.address} coords={coords} />
                        </div>
                        <div className="col-span-2 row-span-3">
                            <CameraComponent setFieldValue={setFieldValue} imageName='punchInImage' />
                        </div>
                    </div>
                    <div className="col-span-2 mt-4">
                        <TextArea
                            name="punchInDesc"
                            label="Description"
                            rows={4}
                            value={values.punchInDesc}
                            onChange={handleChange}
                            error={touched.punchInDesc && errors.punchInDesc ? errors.punchInDesc : ''}
                        />
                    </div>
                    <div className="w-full grid grid-cols-2 gap-6 mt-4">
                        <Button onClick={onClose} type="button" className="h-[50px]" label={cancellabel} />
                        <Button type="submit" style="solid" className="h-[50px]" label={buttonLabel} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default PunchInForm;